import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
       <path d="M5235 7938 c-74 -26 -154 -84 -197 -143 -18 -25 -42 -47 -53 -49
-131 -29 -216 -49 -245 -58 -399 -123 -731 -358 -989 -702 -67 -88 -73 -93
-115 -99 -110 -15 -249 -119 -295 -219 -61 -135 -48 -301 33 -421 14 -20 17
-37 13 -65 -3 -20 -11 -66 -16 -102 -46 -291 -3 -640 112 -929 23 -58 50 -116
61 -128 l19 -22 38 20 c22 11 39 23 39 28 0 8 -18 54 -45 116 -36 81 -78 227
-101 345 -20 108 -33 360 -24 465 9 91 35 239 46 257 3 5 -3 19 -14 31 -48 53
-85 137 -90 206 -4 59 -1 76 23 130 48 107 141 172 255 179 l65 4 34 57 c165
270 472 530 791 668 142 62 230 88 405 121 83 16 91 19 104 47 8 17 18 32 23
33 4 2 8 10 8 17 0 18 61 70 113 97 32 16 60 22 123 22 120 1 187 -38 260
-151 34 -53 39 -55 129 -68 133 -20 319 -77 440 -135 80 -39 198 -104 219
-121 6 -5 32 -24 57 -42 186 -130 350 -302 481 -508 l36 -56 60 -5 c72 -7 117
-27 176 -79 62 -55 89 -116 90 -209 1 -64 -3 -83 -26 -125 -50 -92 -105 -137
-197 -160 -134 -35 -263 25 -329 150 -21 41 -26 64 -26 130 -1 85 17 134 70
198 13 16 24 34 24 40 0 16 -65 120 -116 187 -69 91 -162 183 -285 285 -89 74
-251 163 -403 222 -100 39 -125 46 -236 68 -119 24 -128 22 -150 -27 -70 -159
-252 -228 -404 -153 -61 30 -100 72 -140 154 l-21 43 -58 -6 c-57 -6 -172 -31
-212 -46 -207 -80 -257 -104 -395 -188 -80 -50 -87 -55 -224 -177 -84 -75
-211 -227 -255 -305 l-20 -36 29 -43 c93 -138 91 -278 -5 -388 -53 -60 -116
-92 -199 -101 -60 -7 -61 -7 -74 -47 -41 -124 -41 -457 -2 -640 39 -177 131
-418 166 -432 20 -7 84 17 84 32 0 7 -12 39 -26 71 -49 111 -101 279 -124 399
-19 104 -22 142 -18 295 3 96 8 182 12 191 4 10 28 22 53 28 104 27 195 95
251 191 37 62 51 121 51 215 0 64 -5 92 -25 140 -14 33 -28 68 -31 77 -12 40
173 253 312 359 163 123 330 208 520 265 113 33 116 33 165 -31 49 -63 100
-101 183 -134 49 -20 70 -23 151 -19 80 3 102 8 158 36 61 30 124 86 162 145
21 32 40 33 126 11 190 -48 422 -168 575 -297 89 -74 212 -205 251 -265 33
-51 43 -95 25 -107 -5 -3 -19 -35 -31 -71 -36 -108 -26 -259 21 -325 10 -15
19 -31 19 -36 0 -21 97 -106 154 -135 55 -29 72 -32 168 -36 99 -4 111 -2 164
22 161 74 244 201 245 375 1 76 -2 95 -29 154 -55 121 -146 196 -292 241 -52
17 -58 23 -109 98 -129 190 -296 355 -494 487 -97 66 -259 149 -357 186 -41
15 -84 31 -95 35 -41 16 -133 41 -224 59 -84 18 -131 40 -131 62 0 14 -53 65
-101 97 -109 74 -228 91 -344 50z"/>
<path d="M5321 7688 c-55 -15 -101 -84 -101 -149 0 -40 34 -95 70 -114 39 -20
120 -19 149 1 29 21 60 89 61 132 0 52 -45 113 -92 125 -14 4 -32 9 -40 11 -7
2 -28 0 -47 -6z"/>
<path d="M4861 6814 c-103 -28 -214 -106 -270 -190 -56 -85 -73 -146 -80 -289
l-6 -130 -80 0 -79 0 -9 -43 c-10 -50 -36 -66 -126 -78 -69 -9 -112 -39 -130
-90 -12 -34 -12 -75 -2 -265 7 -123 15 -433 19 -689 7 -443 8 -467 28 -510 51
-110 124 -155 248 -155 104 0 148 19 208 93 54 65 52 39 58 1047 l5 940 24 50
c31 63 82 119 138 151 86 51 116 54 558 54 425 0 453 -3 533 -44 47 -24 116
-97 142 -151 25 -50 25 -51 30 -445 l5 -395 170 -3 c94 -1 179 0 190 2 19 5
20 12 16 166 l-3 160 80 0 c90 0 92 2 92 76 0 55 -3 56 -170 52 l-125 -3 -5
-152 c-3 -92 -9 -156 -16 -163 -6 -6 -31 -10 -55 -8 l-44 3 -6 330 c-6 364 -9
380 -75 480 -65 100 -164 171 -275 199 -91 23 -903 23 -988 0z m-521 -930 c0
-11 15 -14 63 -14 34 0 72 -3 85 -6 l22 -6 -2 -644 -3 -643 -30 -31 c-63 -66
-159 -63 -212 7 l-28 36 1 461 c2 561 20 794 63 840 24 26 41 26 41 0z"/>
<path d="M3653 6619 c-67 -20 -106 -112 -79 -184 41 -106 182 -125 250 -32 28
38 28 122 1 159 -39 52 -110 76 -172 57z"/>
<path d="M6932 6578 c-37 -26 -63 -96 -55 -144 3 -16 23 -46 45 -66 32 -31 47
-38 79 -38 52 0 103 22 124 54 42 63 24 165 -35 199 -41 25 -118 22 -158 -5z"/>
<path d="M4916 6566 c-56 -17 -110 -73 -130 -133 -15 -46 -17 -144 -15 -1088
1 -869 -1 -1039 -12 -1046 -8 -5 -79 -9 -158 -9 -126 0 -148 -2 -176 -20 -74
-46 -113 -151 -85 -234 16 -49 86 -109 146 -124 64 -15 1650 -15 1736 0 134
24 202 178 129 289 -50 75 -74 83 -248 89 -83 3 -155 9 -159 13 -5 4 -7 475
-6 1045 2 838 0 1045 -11 1080 -20 66 -59 107 -122 131 -53 20 -74 21 -453 20
-281 -1 -409 -5 -436 -13z m570 -273 c126 -6 140 -8 161 -29 l23 -23 0 -511 0
-512 -29 -29 -29 -29 -256 0 -255 0 -28 24 -28 24 -3 511 c-2 359 1 517 9 532
26 49 117 58 435 42z m-484 -1525 l33 -53 3 53 c3 50 4 52 33 52 l29 0 0 -95
0 -95 -39 0 c-37 0 -42 3 -75 57 l-36 57 0 -57 c0 -57 0 -57 -30 -57 l-30 0 0
95 0 95 39 0 c36 0 42 -4 73 -52z m402 42 c35 -13 59 -64 52 -106 -10 -50 -47
-74 -115 -74 -62 0 -69 4 -94 40 -30 44 -16 118 27 138 27 14 97 15 130 2z
m360 -19 c39 -37 37 -100 -5 -135 -27 -23 -40 -26 -104 -26 l-74 0 4 38 c1 20
3 63 4 96 l1 58 74 -4 c62 -3 79 -7 100 -27z m-574 -136 c0 -20 -5 -25 -25
-25 -20 0 -25 5 -25 25 0 20 5 25 25 25 20 0 25 -5 25 -25z m354 8 c4 -9 2
-21 -3 -26 -15 -15 -46 -3 -49 21 -3 18 1 22 21 22 14 0 28 -7 31 -17z m688
-520 c23 -21 23 -55 -1 -84 l-19 -24 -836 -3 c-713 -2 -841 0 -870 12 -43 18
-56 55 -32 91 l16 25 862 0 c788 0 863 -1 880 -17z"/>
<path d="M5318 6203 c23 -2 61 -2 85 0 23 2 4 4 -43 4 -47 0 -66 -2 -42 -4z"/>
<path d="M5135 6118 c-3 -13 -4 -165 -3 -338 l3 -315 220 0 220 0 0 335 0 335
-217 3 -218 2 -5 -22z"/>
<path d="M5462 5363 c-50 -3 -70 -7 -66 -15 4 -6 7 -26 6 -45 l-1 -33 83 0
c88 0 96 4 96 50 0 19 -26 52 -38 48 -4 -1 -40 -4 -80 -5z"/>
<path d="M5137 5346 c-3 -8 -4 -22 -2 -32 2 -11 4 -25 4 -31 1 -10 26 -13 91
-13 50 0 90 3 90 6 0 3 -5 14 -10 25 -8 13 -7 25 0 39 10 19 8 20 -79 20 -67
0 -90 -3 -94 -14z"/>
<path d="M5302 4758 c-13 -13 -16 -47 -4 -64 15 -23 61 -25 82 -4 23 23 24 33
10 61 -12 22 -68 27 -88 7z"/>
<path d="M5647 4763 c-4 -3 -7 -24 -7 -45 0 -35 2 -38 29 -38 16 0 36 3 45 6
19 7 21 55 4 72 -13 13 -60 16 -71 5z"/>
<path d="M6627 5924 c-12 -12 -8 -79 5 -92 8 -8 69 -12 203 -13 119 0 202 -5
222 -12 61 -23 119 -73 154 -133 31 -54 34 -66 33 -139 0 -63 -5 -89 -22 -122
-32 -61 -76 -108 -134 -141 -42 -25 -54 -38 -75 -88 -13 -32 -27 -68 -31 -79
-3 -11 -35 -72 -70 -135 -99 -178 -218 -317 -395 -461 -59 -49 -115 -92 -124
-97 -15 -9 -14 -13 8 -40 13 -17 30 -33 37 -36 50 -22 310 208 459 404 72 96
166 263 198 355 16 46 66 105 88 105 19 0 95 86 121 136 32 62 56 151 56 207
-2 142 -97 285 -235 353 -60 29 -60 29 -275 32 -119 2 -219 0 -223 -4z"/>
<path d="M6880 5652 c-24 -11 -43 -32 -59 -66 -22 -44 -23 -53 -12 -95 21 -82
103 -124 183 -95 112 42 121 207 15 257 -45 22 -83 21 -127 -1z"/>
<path d="M6724 5172 c-118 -231 -318 -443 -536 -569 -43 -25 -82 -51 -86 -57
-5 -6 -7 -28 -5 -48 5 -35 7 -37 30 -32 37 10 132 57 199 100 153 97 300 242
392 384 59 91 142 251 142 274 0 8 -19 21 -43 29 l-43 15 -50 -96z"/>
<path d="M3753 4920 c-52 -12 -83 -38 -105 -87 -26 -60 -18 -103 31 -156 37
-39 40 -41 104 -41 64 -1 68 1 103 36 32 31 38 45 42 92 3 44 0 62 -17 89 -31
51 -101 81 -158 67z"/>
<path d="M2550 3789 c-52 -4 -109 -11 -125 -17 -235 -75 -390 -328 -341 -559
38 -183 163 -325 336 -382 l63 -21 2921 0 c3151 0 2937 -3 3046 48 163 76 280
261 280 443 0 74 -31 180 -73 251 -64 109 -174 191 -297 223 -53 14 -363 16
-2887 18 -1556 1 -2871 -1 -2923 -4z m5775 -73 c153 -33 278 -147 319 -290 19
-66 20 -174 2 -241 -41 -157 -170 -274 -332 -304 -76 -14 -5732 -15 -5818 -1
-127 21 -251 112 -306 225 -85 173 -54 363 80 496 64 64 115 93 198 113 82 20
5765 22 5857 2z"/>
<path d="M2636 3538 c-9 -35 -7 -471 3 -486 5 -9 25 -12 57 -10 l49 3 5 130
c3 72 9 130 15 130 5 0 44 -47 88 -105 115 -154 122 -160 178 -160 40 0 48 3
53 23 10 37 7 444 -4 471 -8 22 -16 26 -49 26 -54 0 -61 -16 -61 -142 0 -57
-5 -110 -10 -118 -11 -17 -15 -13 -132 140 -40 52 -80 100 -88 107 -8 7 -34
13 -56 13 -35 0 -43 -4 -48 -22z"/>
<path d="M3193 3553 c-10 -3 -13 -65 -13 -259 l0 -254 54 0 c40 0 55 4 60 16
12 31 7 479 -6 492 -12 12 -71 15 -95 5z"/>
<path d="M3505 3539 c-95 -47 -139 -123 -139 -244 0 -107 37 -174 128 -233 36
-23 52 -26 126 -27 71 0 91 4 125 24 55 32 84 60 104 101 27 54 18 70 -36 70
-39 0 -52 -6 -89 -40 -39 -36 -49 -40 -95 -40 -68 0 -103 22 -129 81 -26 61
-25 86 3 139 51 96 151 106 231 21 25 -26 37 -31 78 -31 45 0 48 2 48 25 0 53
-50 114 -125 154 -54 29 -173 29 -230 0z"/>
<path d="M3940 3541 c-7 -13 -9 -104 -8 -258 l3 -238 188 -3 187 -2 0 34 c0
65 -1 66 -117 66 -123 0 -143 7 -143 55 0 47 20 55 129 55 87 0 91 1 92 23 2
30 -2 61 -8 67 -2 3 -50 7 -106 10 l-102 5 -3 39 c-2 24 2 42 10 47 7 5 64 9
125 9 l113 0 6 26 c4 15 3 39 -2 53 l-9 26 -172 3 c-163 2 -173 1 -183 -17z"/>
<path d="M4716 3539 c-38 -9 -97 -46 -124 -79 -38 -44 -54 -104 -50 -183 4
-67 9 -83 35 -120 17 -23 52 -55 78 -72 43 -27 57 -30 129 -30 142 0 217 58
244 190 21 101 -15 202 -92 254 -69 48 -136 60 -220 40z m161 -94 c89 -59 95
-201 12 -277 -38 -35 -47 -38 -99 -38 -43 0 -65 6 -91 23 -43 29 -79 92 -79
139 0 75 53 154 115 171 33 9 116 -1 142 -18z"/>
<path d="M6286 3504 c-11 -87 -7 -439 6 -452 15 -15 163 -16 243 -1 130 24
205 115 205 250 0 101 -53 185 -144 227 -39 19 -63 22 -175 22 l-128 0 -7 -46z
m285 -77 c70 -47 89 -147 40 -216 -30 -41 -64 -58 -137 -66 -89 -10 -94 -2
-94 140 0 63 4 125 10 139 9 24 14 26 78 26 55 0 76 -5 103 -23z"/>
<path d="M6953 3528 c-6 -13 -28 -63 -48 -113 -20 -49 -43 -104 -51 -121 -32
-72 -84 -217 -84 -235 0 -29 76 -27 101 3 11 11 19 26 19 33 0 31 36 45 117
45 45 0 88 -3 97 -6 8 -3 25 -26 36 -50 21 -42 24 -44 65 -44 50 0 57 11 39
60 -59 162 -170 436 -178 442 -6 4 -32 8 -57 8 -38 0 -48 -4 -56 -22z m87
-198 c11 -21 20 -51 20 -65 0 -25 -7 -29 -55 -28 -46 1 -48 2 -41 34 8 40 36
99 47 99 5 0 18 -18 29 -40z"/>
<path d="M7231 3536 c-7 -8 -11 -27 -9 -43 3 -26 7 -29 63 -37 33 -4 66 -10
73 -12 9 -2 12 -43 13 -156 0 -84 4 -173 7 -198 7 -44 8 -45 48 -48 l41 -3 7
47 c3 26 6 116 6 200 0 103 4 155 11 160 13 8 36 11 97 13 l43 1 -3 43 -3 42
-191 3 c-158 2 -193 0 -203 -12z"/>
<path d="M7767 3518 c-9 -18 -27 -63 -41 -101 -14 -37 -33 -84 -41 -105 -9
-20 -23 -53 -32 -72 -32 -74 -63 -163 -63 -182 0 -17 6 -19 48 -16 42 3 49 7
63 35 32 63 33 64 138 61 l96 -3 25 -47 c24 -46 27 -48 68 -48 28 0 45 5 48
15 5 12 -40 146 -81 240 -7 17 -29 70 -49 119 -52 130 -57 136 -115 136 -45 0
-50 -2 -64 -32z m109 -213 c9 -24 13 -48 9 -54 -9 -16 -95 -14 -101 2 -5 13
20 82 38 105 15 18 33 0 54 -53z"/>
<path d="M5146 3524 c-3 -9 -6 -116 -6 -239 0 -214 1 -223 20 -228 11 -3 28
-3 39 0 17 4 19 18 23 134 2 78 8 133 15 140 15 15 38 -10 150 -159 83 -111
86 -113 121 -110 l37 3 3 226 c2 197 0 228 -14 239 -13 11 -17 11 -24 1 -6
-11 -9 -11 -15 0 -15 24 -24 -23 -24 -136 -2 -181 -10 -179 -176 40 -37 50
-73 93 -79 97 -20 14 -64 8 -70 -8z"/>
<path d="M5672 3298 l3 -243 75 -3 c41 -2 117 0 168 3 l92 7 0 28 0 28 -97 7
c-54 3 -109 7 -123 8 -23 2 -25 6 -28 60 -2 40 1 59 10 62 7 2 54 6 103 7 l90
3 3 33 3 32 -83 0 c-118 0 -124 3 -132 56 -9 69 -7 70 106 78 79 6 104 11 121
27 20 18 20 19 1 20 -19 0 -19 1 1 8 26 11 -79 21 -212 21 l-103 0 2 -242z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
